import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import history from "../history";
//import { AUTH_TOKEN } from "redux/constants/Auth";
import { notification } from "antd";
import store from '../redux/store';
import {setUser, signOut} from "../redux/actions";



const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
  withCredentials: true,
});

// Config
const ENTRY_ROUTE = "/auth/login";
//const TOKEN_PAYLOAD_KEY = "authorization";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    if (config.headers[PUBLIC_REQUEST_KEY]) {
      config.withCredentials = false;
    }
    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: "Server Error please try again",
      description: JSON.stringify(error.response?.data ),
    };
    console.log(ENTRY_ROUTE , window.location.pathname )
    if (error.response) {
      // Remove token and redirect
      if (error.response.status === 403) {
        notificationParam.message = "Authentication Fail";
        notificationParam.description = "Please login again";
        store.dispatch(signOut())
        history.push(ENTRY_ROUTE);
        window.location.reload();
      }

      if (error.response.status === 401) {
        if(error.response.data?.message === "Missing JWT Refresh Token"  && window.location.pathname  !== ENTRY_ROUTE ) {
          console.log('Its In Missing JWT Refresh Token')
            notificationParam.message = "Refresh Token Not Found";
            notificationParam.description = "Please login again";
            store.dispatch(setUser({
                        username: "",
                        role: "ROLE_USER",
                        code: "",
            }))
            history.push(ENTRY_ROUTE)
        }else if(error.response.data?.message === "JWT Token not found" && window.location.pathname  !== ENTRY_ROUTE && window.location.pathname  !== '/notfound' ){
          console.log('JWT Token not found')
          notificationParam.message = "UNAUTHORIZED";
          notificationParam.description= "please refresh the page"
          window.location.reload();
        }
        if(window.location.pathname  === ENTRY_ROUTE ){
          return ;
        }
        console.log('Its In 401')
      }

      if (error.response.status === 404) {
        notificationParam.message = "Not Found";
        notificationParam.description = error.response.data.error.details;
      }

      if (error.response.status === 400) {
        notificationParam.message = "Not Found";
        notificationParam.description = error.response.data.error.details;
      }

      if (error.response.status === 500) {
        notificationParam.message = "Internal Server Error";

        notificationParam.description = error.response.data.error.details;
      }

      if (error.response.status === 508) {
        notificationParam.message = "Time Out";
        notificationParam.description = error.response.data.error.details;
      }
    } else {
      notificationParam.message = "No Server Response";
      if(error.response !== undefined) {
        if (error.response.data.error.details !== undefined) {
          notificationParam.description = error.response.data.error.details;
        }
      }
      //localStorage.removeItem(AUTH_TOKEN);
      //history.push(ENTRY_ROUTE);
      //window.location.reload();
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
