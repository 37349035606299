import fetch from "auth/FetchInterceptor";
import JwtAuthService from "./JwtAuthService";
import {useDispatch} from "react-redux";
import {signOut, updateCredit} from "../redux/actions";

const UserService = {};
const serviceBase = "/user";


UserService.getPlayers = function (params) {
  return fetch({
    url: "/players",
    method: "GET",
    params,
  });
};

UserService.getAdmins = function () {
  return fetch({
    url: serviceBase + "/getAllAdmins",
    method: "GET",
  });
};


UserService.changePassword = function (params) {
  return fetch({
    url: serviceBase + "/password",
    method: "Post",
    data: params,
  });
};

UserService.startMeeting = function (params) {
  return fetch({
    url: serviceBase + "/addMeeting",
    method: "Post",
    data: params,
  });
};

UserService.StopMeeting = function (params) {
  return fetch({
    url: serviceBase + "/stopMeeting",
    method: "Get",
  });
};

UserService.block = function (params) {
  return fetch({
    url: serviceBase + "/block",
    method: "Post",
    data: params,
  });
};

UserService.modify = function (params) {
  return fetch({
    url: serviceBase + "/modify",
    method: "Post",
    data: params,
  });
};

UserService.getDashboard = function(data) {
  return fetch({
    url: serviceBase + "/adminDashboard",
    method: "Post",
    data : data
  });
}

UserService.getGoals = function(data) {
  return fetch({
    url: serviceBase + "/monthlyGoals",
    method: "Post",
    data : data
  });
}


UserService.getUsersByRole = function () {
  return fetch({
    url: serviceBase + "/getAll",
    method: "Get",
  });
};

UserService.getClients = function (params) {
  return fetch({
    url: serviceBase + "/getClients",
    method: "post",
    data: {
      ...params,
    },
  });
};
UserService.ValidateCin = function (data) {
  return fetch({
    url: "/validateCin",
    method: "post",
    data: data,
  });
};

UserService.addUser = function (data) {
  return fetch({
    url: serviceBase + "/addAdmin",
    method: "post",
    data: data,
  });
};

UserService.ModifyUser = function (data) {
  return fetch({
    url: serviceBase + "/subUserPassword",
    method: "post",
    data: data,
  });
};

UserService.addTransaction = function (data) {
  return fetch({
    url: "/transaction/add",
    method: "post",
    data: data,
  });
};

UserService.getTransactionTo = function (data) {
  return fetch({
    url: "/transaction/getAllWithUser/" + parseInt(data),
    method: "get",
  });
};

UserService.getUserByCode = function (data) {
  return fetch({
    url: serviceBase + "/getUserByCode/" + data,
    method: "get",
  });
};

UserService.getUserByCin = function (data) {
  return fetch({
    url: serviceBase + "/getUserByCin/" + data,
    method: "get",
  });
};



export default UserService;
